import Markdown from 'markdown-to-jsx'
import * as React from 'react'
import {useMemo, useState, useEffect, useCallback} from 'react'
import MdxProviderComponent from '../components/molecules/mdx-provider/mdx-provider-component'
import PostSeoComponent from '../components/molecules/post-seo/post-seo-component'
import profileJPEG from '../images/profile.jpg'
import PostLayout from '../layouts/post-layout'

const markdown = `
I'm a full-stack web developer, located in Germany.

I do:
- Frontend development (React, Angular, Svelte, Next.js, Gatsby)
- Backend development (Ruby/Ruby on Rails, Node.js)
- DevOps (GitLab CI/CD, AWS, Docker, Traefik, Linux related stuff)
- Project management
- And everything in between

On a hobby-level, I also do:
- Electrical engineering
- Tinkering
- Fixing broken stuff

Check out my [blog posts](/posts).
`

const Page = () => {
  const welcomeEmojis = useMemo<string[]>(() => ['👋', '👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿'], [])
  const [currentWelcomeEmoji, setCurrentWelcomeEmoji] = useState<string>(welcomeEmojis[0])

  const changeWelcomeEmoji = useCallback((newIndex: number) => {
    setCurrentWelcomeEmoji(welcomeEmojis[newIndex])
  }, [welcomeEmojis])

  useEffect(() => {
    let index = 1
    const intervalRef = setInterval(() => {
      changeWelcomeEmoji(index)
      index++

      if (index > welcomeEmojis.length - 1 ) {
        index = 0
      }
    }, 1000)

    return () => {
      clearInterval(intervalRef)
    }
  }, [changeWelcomeEmoji, welcomeEmojis])

  return (
    <PostLayout className="index-page">
      <PostSeoComponent path='/'/>
      <div className="index-page__profile">
        <img className="index-page__profile-image" src={profileJPEG} />
      </div>
      <h2> Hi there, my name is Max. {currentWelcomeEmoji}</h2>
      <MdxProviderComponent>
        <Markdown>{markdown}</Markdown>
      </MdxProviderComponent>
    </PostLayout>
  )
}

export default Page
